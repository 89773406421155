import React, { useEffect } from 'react';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import CookieBanner from './CookieBanner/CookieBanner';

import '../../css/layout.css';

const Layout = ({ children, mainClass }) => {
    // INITIALIZE GOOGLE OPTIMIZE EXPERIMENT ON 'optimize.activate'
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'optimize.activate' });
    }, []);

    return (
        <>
            <Header />
            <main className={mainClass}>{children}</main>
            <Footer />
            <CookieBanner />
        </>
    );
};

export default Layout;
