/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

import * as styles from '../../../css/components/Form.module.scss';

const ContactForm = () => {
    const handleSubmitForm = (values) => {
        window.location.href = `mailto:hello@joinripples.org?subject=Schools%20contact%20form&body=Name:%20${values.name}%0DEmail:%20${values.email}%0DSchool:%20${values.school}%0D%0D${values.message}`;
    };

    const contactFormSchema = yup.object({
        name: yup.string().required('Name required'),
        email: yup
            .string()
            .email('Invalid email address')
            .required('Email required'),
        school: yup.string().required('School required'),
        message: yup.string(),
    });

    const initialValues = {
        'form-name': 'school-contact',
        name: '',
        email: '',
        school: '',
        message: '',
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmitForm(values)}
            validationSchema={contactFormSchema}>
            {({ isSubmitting }) => (
                <Form
                    name="school-contact"
                    className={styles.Form}
                    data-netlify="true"
                    data-netlify-honeypot="bot-field">
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input
                        type="hidden"
                        name="form-name"
                        value="school-contact"
                    />
                    <label hidden>
                        Don’t fill this out: <input name="bot-field" />
                    </label>
                    <div className={styles.formGroup}>
                        <label htmlFor="name">Your name*</label>
                        <Field name="name">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="name"
                                    disabled={isSubmitting}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="name">
                            {(msg) => <p>{msg}</p>}
                        </ErrorMessage>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="email">Your email*</label>
                        <Field name="email">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="email"
                                    id="email"
                                    disabled={isSubmitting}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="email">
                            {(msg) => <p>{msg}</p>}
                        </ErrorMessage>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="school">Your school*</label>
                        <Field name="school">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="school"
                                    disabled={isSubmitting}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="school">
                            {(msg) => <p>{msg}</p>}
                        </ErrorMessage>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="message">Your message</label>
                        <Field name="message">
                            {({ field }) => (
                                <textarea
                                    {...field}
                                    id="message"
                                    disabled={isSubmitting}
                                />
                            )}
                        </Field>
                    </div>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        style={isSubmitting ? { opacity: 0.8 } : {}}
                        data-ga-id="schools_get_started__submit_form">
                        {isSubmitting ? 'Thank you!' : 'Submit'}
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default ContactForm;
