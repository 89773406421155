import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import * as styles from '../css/pages/Schools.module.scss';

import SEO from '../components/layout/SEO';
import Layout from '../components/layout/Layout';
import ContactForm from '../components/forms/ContactForm/ContactForm';

import { getAltTag } from '../util';

const Schools = () => {
    const data = useStaticQuery(graphql`
        {
            allFile(
                filter: {
                    extension: { regex: "/(jpg)|(png)|(jpeg)/" }
                    relativeDirectory: { eq: "schools" }
                }
                sort: { fields: relativePath }
            ) {
                edges {
                    node {
                        base
                        id
                        childImageSharp {
                            gatsbyImageData(
                                layout: FULL_WIDTH
                                placeholder: BLURRED
                            )
                        }
                    }
                }
            }
            currentBuildDate {
                currentDate
            }
        }
    `);

    const schemaData = {
        '@context': 'https://schema.org',
        '@graph': [
            {
                '@type': 'Organization',
                '@id': 'https://www.joinripples.org/#organization',
                name: 'Ripples',
                url: 'https://www.joinripples.org/',
                sameAs: [],
                logo: {
                    '@type': 'ImageObject',
                    '@id': 'https://www.joinripples.org/#logo',
                    url: 'https://roundups-marketing-site.s3.eu-west-1.amazonaws.com/images/ripples-icon-white-bg.png',
                    width: 500,
                    height: 500,
                    caption: 'Ripples',
                },
                image: {
                    '@id': 'https://www.joinripples.org/#logo',
                },
            },
            {
                '@type': 'WebSite',
                '@id': 'https://www.joinripples.org/#website',
                url: 'https://www.joinripples.org/',
                name: 'Ripples',
                description: 'Donate pennies as you spend',
                publisher: {
                    '@id': 'https://www.joinripples.org/#organization',
                },
            },
            {
                '@type': 'WebPage',
                '@id': 'https://www.joinripples.org/schools/#webpage',
                url: 'https://www.joinripples.org/schools/',
                inLanguage: 'en-GB',
                name: 'Schools | Ripples',
                isPartOf: {
                    '@id': 'https://www.joinripples.org/#website',
                },
                datePublished: '2019-08-01T12:37:42+00:00',
                dateModified: data.currentBuildDate.currentDate,
            },
        ],
    };

    const mobileProjects = data.allFile.edges[0];
    const mobileNotifications = data.allFile.edges[1];
    const easyImage = data.allFile.edges[2];
    const engagingImages = [data.allFile.edges[3], data.allFile.edges[4]];

    const targetReachedGif = '../images/schools/targetReached.gif';

    const renderImage = (i) => (
        <GatsbyImage
            className={styles.image}
            image={data.allFile.edges[i].node.childImageSharp.gatsbyImageData}
            alt={getAltTag(data.allFile.edges[i].node)}
        />
    );

    const scrollToForm = () => {
        const element = document.getElementById('getStarted');
        const y =
            element.getBoundingClientRect().top + window.pageYOffset + -75;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    return (
        <>
            <SEO
                title="Schools"
                description="Schools are using Ripples technology to help their fundraising efforts. Help raise for school projects easily and effectively with Ripples"
                path="/schools/"
                schemaData={[schemaData]}
            />
            <Layout mainClass={styles.Schools}>
                <div className={styles.mobileProjects}>
                    <GatsbyImage
                        image={
                            mobileProjects.node.childImageSharp.gatsbyImageData
                        }
                        alt="School projects on mobile"
                    />
                </div>
                <div className={styles.mobileNotifications}>
                    <GatsbyImage
                        image={
                            mobileNotifications.node.childImageSharp
                                .gatsbyImageData
                        }
                        alt="School notifications on mobile"
                    />
                </div>
                <section className={styles.intro}>
                    <h1>
                        Fund amazing projects for your school with spare change
                    </h1>
                    <p>
                        It's effortless and engaging digital fundraising that
                        makes a real difference
                    </p>
                    <div className={styles.ctas}>
                        <Link
                            to="/join/"
                            data-ga-id="schools_intro__join"
                            className={styles.primaryButton}>
                            Get started
                        </Link>
                        {/* eslint-disable-next-line react/button-has-type */}
                        <button
                            data-ga-id="schools_intro__more_info"
                            className={styles.primaryButton}
                            onClick={scrollToForm}>
                            Request more info
                        </button>
                        <a
                            className={styles.primaryButton}
                            href="https://calendly.com/ripples-team/30min"
                            rel="noopener noreferrer"
                            target="_blank"
                            data-ga-id="schools_intro__book_a_call">
                            Book a call
                        </a>
                    </div>
                </section>
                <section className={styles.usps}>
                    <div className={`${styles.usp} ${styles.cream}`}>
                        <h2>Easy</h2>
                        <p>For parents and carers to sign up</p>
                        <GatsbyImage
                            image={
                                easyImage.node.childImageSharp.gatsbyImageData
                            }
                            alt={getAltTag(easyImage.node)}
                        />
                    </div>
                    <div className={`${styles.usp} ${styles.light}`}>
                        <h2>Engaging</h2>
                        <p>Follow your fundraiser's progress</p>
                        <div className={styles.engagingImages}>
                            {engagingImages.map((image, i) => (
                                <div
                                    className={styles.imageContainer}
                                    key={image.node.id}
                                    style={
                                        i === 0
                                            ? { transform: 'rotate(-5deg)' }
                                            : { transform: 'rotate(5deg)' }
                                    }>
                                    <GatsbyImage
                                        className={styles.image}
                                        image={
                                            image.node.childImageSharp
                                                .gatsbyImageData
                                        }
                                        alt={getAltTag(image.node)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className={`${styles.usps} ${styles.mobileReverse}`}>
                    <div className={styles.usp}>
                        <h2>Powerful</h2>
                        <p>Your school can raise thousands every year</p>
                        <StaticImage
                            className={styles.gif}
                            src={targetReachedGif}
                            alt="School project target reached"
                        />
                    </div>
                    <div className={`${styles.usp} ${styles.blue}`}>
                        <h2>Zero risk</h2>
                        <p>
                            No setup costs. No monthly subscriptions. A simple
                            5% transaction cost
                        </p>
                        <h3>
                            &pound;0 setup
                            <br />
                            &pound;0 per month
                        </h3>
                    </div>
                </section>
                <section className={styles.howItWorks}>
                    <h2>How it works</h2>
                    <div className={styles.container}>
                        <div className={styles.stepContainer}>
                            <div className={styles.imageContainer}>
                                {renderImage(5)}
                            </div>
                            <div className={styles.step}>
                                <div className={styles.number}>1</div>
                                <div className={styles.copy}>
                                    <p className={styles.title}>
                                        Create your campaign
                                    </p>
                                    <p className={styles.body}>
                                        Complete{' '}
                                        <Link
                                            to="/join/"
                                            data-ga-id="schools_how_it_works__join">
                                            this form
                                        </Link>{' '}
                                        to write your school’s fundraising
                                        campaign
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.stepContainer}>
                            <div className={styles.step}>
                                <div className={styles.number}>2</div>
                                <div className={styles.copy}>
                                    <p className={styles.title}>
                                        Launch and engage
                                    </p>
                                    <p className={styles.body}>
                                        We’ll support you with a tailored launch
                                        kit and an engaging campaign plan
                                    </p>
                                </div>
                            </div>
                            <div className={styles.imageContainer}>
                                <div className={styles.imageContainer}>
                                    {renderImage(6)}
                                </div>
                            </div>
                        </div>
                        <div className={styles.stepContainer}>
                            <div className={styles.imageContainer}>
                                <div className={styles.imageContainer}>
                                    {renderImage(7)}
                                </div>
                            </div>
                            <div className={styles.step}>
                                <div className={styles.number}>3</div>
                                <div className={styles.copy}>
                                    <p className={styles.title}>
                                        Reach your target
                                    </p>
                                    <p className={styles.body}>
                                        Celebrate with your school community and
                                        bring your project to life!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={styles.getStarted} id="getStarted">
                    <h2>Get started</h2>
                    <div className={styles.container}>
                        <Link
                            to="/join/"
                            data-ga-id="schools_get_started__join"
                            className={styles.primaryButton}>
                            Create campaign now
                        </Link>
                        <span>or</span>
                        <a
                            className={styles.primaryButton}
                            href="https://calendly.com/ripples-team/30min"
                            rel="noopener noreferrer"
                            target="_blank"
                            data-ga-id="schools_get_started__book_a_call">
                            Book a call
                        </a>
                        <span>or</span>
                        <h3>Request a free info pack here:</h3>
                        <ContactForm />
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Schools;
