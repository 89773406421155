// extracted by mini-css-extract-plugin
export var Schools = "Schools-module--Schools--0l27K";
export var blue = "Schools-module--blue--5DZ3I";
export var body = "Schools-module--body--p77TC";
export var container = "Schools-module--container--OB1Bt";
export var copy = "Schools-module--copy--FkLGp";
export var cream = "Schools-module--cream--Elzr7";
export var ctas = "Schools-module--ctas--zUYzk";
export var engagingImages = "Schools-module--engagingImages--1MeuL";
export var getStarted = "Schools-module--getStarted--HZazQ";
export var gif = "Schools-module--gif--ORkXI";
export var howItWorks = "Schools-module--howItWorks--GFW81";
export var image = "Schools-module--image--k3dn4";
export var imageContainer = "Schools-module--imageContainer--gRX4j";
export var intro = "Schools-module--intro--c-y7D";
export var light = "Schools-module--light--bQHp3";
export var mobileNotifications = "Schools-module--mobileNotifications--XWGHI";
export var mobileProjects = "Schools-module--mobileProjects--Kitcj";
export var mobileReverse = "Schools-module--mobile-reverse--FHDlx";
export var number = "Schools-module--number--bNEC+";
export var primaryButton = "Schools-module--primaryButton--FqURC";
export var step = "Schools-module--step--1eplO";
export var stepContainer = "Schools-module--stepContainer--7u1NG";
export var title = "Schools-module--title--Yyua1";
export var usp = "Schools-module--usp--+6ML9";
export var usps = "Schools-module--usps--2Bd--";