export const getAltTag = (logo) => {
    function createAltTag({ base }) {
        const imageNameArray = base.split('-');
        imageNameArray.shift();
        const imageNameString = imageNameArray.join(' ');
        return imageNameString.slice(0, -4);
    }
    return createAltTag(logo);
};

export const formatDate = (
    timestamp,
    monthFormat = 'long',
    yearFormat = 'long'
) => {
    // Create a date object from the timestamp
    const date = new Date(timestamp);

    // Create a list of names for the months
    const months =
        monthFormat === 'long'
            ? [
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December'
              ]
            : [
                  'Jan',
                  'Feb',
                  'Mar',
                  'Apr',
                  'May',
                  'June',
                  'July',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec'
              ];

    const year =
        yearFormat === 'long'
            ? date.getFullYear()
            : date.getFullYear().toString().substr(-2);

    // return a formatted date
    return `${getOrdinalNum(date.getDate())} ${
        months[date.getMonth()]
    }, ${year}`;
};

function getOrdinalNum(n) {
    return (
        n +
        (n > 0
            ? ['th', 'st', 'nd', 'rd'][
                  (n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10
              ]
            : '')
    );
}
